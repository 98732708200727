import React, { useEffect, useState } from "react";
import "./StudentCard.scss";
import AvatarImg from "../../../../assets/images/avatar-default.png";
import Cake from "../../../../assets/icons/cake.svg";
import Injury from "../../../../assets/icons/injury.svg";
import Pregnancy from "../../../../assets/icons/pregnancy.svg";
import QRCode from "../../../../assets/icons/qr.svg";
import { UserT } from "../../../../interfaces/User";
import useDates from "../../../../hooks/useDates";
import { useClassContext } from "../../../../context/ClassContext";

type StudentCardProps = {
  student: UserT;
};

const noInjuryOptions = ["no", "ninguna"];

const StudentCard = ({ student }: StudentCardProps) => {
  const { primaryFormatDate, isBirthday } = useDates();
  const { clase, takingAttendance, handleAttendance, attendance } =
    useClassContext();
  const [moreInfo, setMoreInfo] = useState<boolean>(false);

  return (
    <div
      className={
        "student-card-component " +
        (attendance.includes(student.id) ? "attended" : "not-attended")
      }
    >
      {student.check_by_qr === 1 && (
        <img className="qr-code-img" src={QRCode} alt={"qr code"} />
      )}
      <div className="icons-container">
        {student.metadata?.injury &&
          student.metadata.injury !== "" &&
          !noInjuryOptions.includes(student.metadata.injury.toLowerCase()) && (
            <div className="icon-container">
              <img src={Injury} alt={"injury"} />
            </div>
          )}
        {student.metadata?.pregnancy &&
          student.metadata.pregnancy.toLowerCase() === "si" && (
            <div className="icon-container">
              <img src={Pregnancy} alt={"Pregnancy"} />
            </div>
          )}
        {clase &&
          student.metadata &&
          isBirthday(student.metadata.birthdate, clase?.startDate) && (
            <div className="icon-container">
              <img src={Cake} alt={"cake"} />
            </div>
          )}
      </div>
      <div className="image-container">
        <img src={AvatarImg} alt={"avatar"} />
      </div>
      <div
        className="info"
        role="button"
        onClick={() => setMoreInfo(!moreInfo)}
      >
        {student.isFirstClass && (
          <h3 className="fs-16 fw-bold text-success">Primera clase</h3>
        )}
        {student.isTestClass ? (
          <h3 className="fs-16 fw-bold text-danger">Clase prueba</h3>
        ) : (
          <></>
        )}
        <h3
          className={`fs-16 fw-bold ${
            student?.isAllAccess ? "all-access" : ""
          }`}
        >
          {student.name} {student.lastname}
        </h3>
        {moreInfo && student.metadata.birthdate && (
          <h4 className="fs-14">
            {primaryFormatDate(student.metadata.birthdate)}
          </h4>
        )}
        {moreInfo && student.metadata.injury && (
          <h4 className="fs-14">Lesión: {student.metadata.injury}</h4>
        )}
        {moreInfo && student.metadata.pregnancy && (
          <h4 className="fs-14">Embarazo: {student.metadata.pregnancy}</h4>
        )}
      </div>
      {takingAttendance && (
        <div className="d-flex flex-column">
          <input
            id={"attendance-" + student.id}
            type="checkbox"
            checked={attendance.includes(student.id)}
            onChange={() => handleAttendance(student.id)}
          />
          <label className="fs-12" htmlFor={"atendance-" + student.id}>
            Asistencia
          </label>
        </div>
      )}
    </div>
  );
};

export default StudentCard;
